/*
 * 底部
 * 
 * @since 2018-5-18
 * @author xieyun
 */
#footer {
  background: url('../img/footer-bg.png') no-repeat center bottom;
  height: 287px;
}
#footer .container {
  width: 1200px;
  padding-top: 36px;
}

/*销售保证*/
#footer .footer-guarantee {
  padding: 40px 155px;
  border-bottom: 1px solid $border;
}
#footer .guarantee-item {
  text-align: center;
  .icon,
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  .icon {
    font-size: 46px;
    color: $font-lighter;
  }
  .text {
    color: $font-lighter;
  }
}

/*友情链接*/
#footer .main-link {
  font-size: 0;
  text-align: center;
  padding: 35px 0 15px;
  .link-item,
  .link-line {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: $font-light;
  }
  .link-item:hover {
    color: $color-skin-base;
  }
  .link-line {
    width: 1px;
    height: 13px;
    margin: 0 10px;
    background-color: $bg-line;
  }
}
#footer .main-info {
  color: $font-light;
  text-align: center;
  .info-item {
    margin: 0 2px;
  }
}