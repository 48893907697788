/*
 * 全局样式
 *
 * @since 2018-5-16
 * @author xieyun
 */
body {
  font-family: "Microsoft Yahei";
  background-color: $body-bg;
  font-size: 14px;
  color: $text-color;
}
html {
  height: 100%;
}


/*
 * 包裹，一般样式都会包在该标签下，以使用一致的宽度
 *
 * @since 2018-5-16
 * @author xieyun
 */
.container {
  position: relative;
  width: 1160px;
  margin: 0 auto;
  padding: 0;
}
#bodyer {  
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: 650px;
}


/*
 * 链接
 *
 * @since 2018-5-16
 * @author xieyun
 */
a {
  @include link-colors($link-color, $link-hover-color, $link-hover-color);
}


/*
 * 按钮
 *
 * @since 2018-5-17
 * @author xieyun
 */
.btn {
  &:focus,
  &:active:focus,
  &.foucs,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    outline: none;
  }
}


/*
 * 截取
 *
 * @since 2018-5-17
 * @author xieyun
 */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/*
 * 轮播图样式重置
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.carousel-control.left,
.carousel-control.right {
  background-image: none;
  text-shadow: none;
  opacity: 1;
}


/*
 * 标题
 *
 * @since 2018-5-20
 * @author xieyun
 */
h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: 0;
}


/*
 * 字体大小+margin间距
 *
 * @since 2018-5-17
 * @author xieyun
 */
@for $i from 1 through 10 {
  .mr#{$i*5} {
    margin-right: 5px * $i;
  }
  .ml#{$i*5} {
    margin-left: 5px * $i;
  }
  .mt#{$i*5} {
    margin-top: 5px * $i;
  }
  .mb#{$i*5} {
    margin-bottom: 5px * $i;
  }
  .f#{$i*2+10} {
    font-size: 2px * $i + 10;
  }
}


/*
 * 栅格系统
 *
 * @since 2018-5-19
 * @author xieyun
 */
@for $i from 1 through 12 {
    @each $animal in xs, sm, md, lg{
      .col-#{$animal}-#{$i}{
        padding-left: 0;
        padding-right: 0;
      }
    }
}


// @each $animal, $color in (price, $text-price),
// (dark, $color-dark),
// (color, $color),
// (rarity-normal, $brand-rarity-normal),
// (rarity-uncommon, $brand-rarity-uncommon),
// (rarity-rare, $brand-rarity-rare),
// (rarity-mythical, $brand-rarity-mythical),
// (rarity-immortal, $brand-rarity-immortal),
// (rarity-ancient, $brand-rarity-ancient),
// (rarity-legendary, $brand-rarity-legendary),
// (rarity-arcana, $brand-rarity-arcana){
//   .text-#{$animal} {
//     color: #{$color};
//   }
// }


// @each $animal, $color in (normal, $brand-rarity-normal) {
//   .b-t-rarity-#{$animal}{
//     border-top: 1px solid $color !important;
//   }
// }






