// .bootstrap-select.select-country-code{
//     &.open{
//         >.dropdown-toggle.btn-default:focus{
//             color: $color-dark;
//             background-color: transparent;
//             border: 0;
//         }
//     }
//     >.dropdown-toggle{
//         padding-right: 10px;
//         padding-left: 5px;
//         color: $color-dark;
//         background-color: transparent;
//         border: 0;
//         font-size: 12px;
//         &:hover{
//             background-color: transparent;
//             border: 0;
//             color: $color-dark;
//         }
//         &:focus{
//             outline: none!important;
//         }
//         .filter-option{
//             text-align: center;
//         }
//         .caret{
//             right: 5px;
//         }
//     }
//     .dropdown-menu{
//         font-size: 12px;
//         width: 200px;
//         background-color: $bg-light;
//         >li{
//             >a{
//                 color: $color;
//                 padding: 6px 20px;
//                 span.text{
//                     display: block;
//                 }
//                 .text-muted{
//                     float: right;
//                     color: $color;
//                 }
//                 &:hover{
//                     background-color: $bg-base;
//                 }
//                 &:focus{
//                     outline: none;
//                 }
//             }
//             &.no-results{
//                 color: $color;
//                 background-color: transparent;
//             }
//         }
//         .bs-searchbox{
//             position: relative;
//             padding: 5px;
//             &::after{
//                 font-family: "iconfont" !important;
//                 content: "\e732";
//                 position: absolute;
//                 top: 12px;
//                 left: 15px;
//                 font-size: 12px;
//                 z-index: 3;
//                 color: $color-dark;
//                 pointer-events: none;
//                 text-align: center;
//                 width: 20px;
//             }
//             .form-control{
//                 height: 30px;
//                 font-size: 12px;
//                 padding: 4px 12px 4px 35px;
//             }
//         }
//         >.active>a,
//         >.active>a:focus,
//         >.active>a:hover{
//             color: $color;
//             background-color: $bg-base;
//         }
//     }
// }
// .bs-container.select-country-code{
//     &::before{
//         position: absolute;
//         left: 20px;
//         top: -17px;
//         content: "";
//         border: 10px solid transparent;
//         border-bottom: 10px solid $bg-light;
//         z-index: 1061;
//     }
// }
// .input-goods{
//     position: relative;
//     &:after{
//         content: "";
//         width: 31px;
//         height: 32px;
//         background: url('../img/select-bg.jpg') no-repeat right center;
//         position: absolute;
//         right: 0.5%;
//         top: 0;
//         pointer-events: none;
//     }
//     select{
//         border-color: $divider-color;
//         outline: none;
//         width: 160px;
//         height: 32px;
//         line-height: 32px;
//         appearance: none;
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         padding-left: 10px;
//         cursor: pointer !important;
//     }
// }


.bootstrap-select.gx-select {
    &.open {
        .dropdown-toggle {
            border: 1px solid $divider-color; 
        }
    }
    
    .dropdown-toggle {
        padding: 0 10px;
        height: 30px;
        color: $font;
        background-color: $bg;
        font-size: 12px;
        box-shadow: none;
        padding-right: 40px;
        margin-left: 0;
        &:active,
        &:hover,
        &:focus {
            background-color: $bg;
            color: $font;
        }
        &:focus {
            outline: none !important;
        }
        .caret {
            width: 30px;
            text-align: center;
            height: 28px; 
            line-height: 28px;
            font-size: 12px; 
            color: $font-light;
            border: 0 none;
            border-left: 1px solid $divider-color;
            background-color: $body-bg;
            top: 2px;
            right: 0;
            &:after {
                font-family: "iconfont" !important;
                content: "\e622";
            }
        }
    }

    .dropdown-menu {
        font-size: 12px;
        box-shadow: none;
        border-radius: 1px;
        border: 1px solid $divider-color;
        margin: -1px 0;
        padding: 0;
        a {
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            background-color: $bg;
            outline: none;
            &:hover {
                color: $color-skin-base;
                background-color: $color-skin-lighter; 
            }
        }
    }
}