.btn-default {
    height: 36px;
    background-color: $bg;
    border-color: $divider-color;
    border-radius: 1px;
    color: $font; 
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $bg;
        border-color: $divider-color;
        color: $font;
        box-shadow: none;
    }  
}

.btn-primary {
    height: 36px;
    background-color: $color-skin-base;
    border-color: $color-skin-base;
    border-radius: 1px;
    color: $font-lighter;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $color-skin-dark;
        border-color: $color-skin-dark;
        color: $font-lighter;
    }
}
.btn-buy {
    background-color: $bg;
    color: $color-skin-base;
    border-radius: 0;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $color-skin-lighter;
        border-color: $color-skin-base;
        color: $color-skin-base;
    }
}
.btn-order {
    background-color: $bg-cart;
    border-color: $border-gift;
    color: $font-lighter;
    border-radius: 0;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $bg-cart;
        border-color: $border-gift;
        color: $font-lighter;
    } 
}
.btn-no {
    background-color: $bg-indicators;
    border-color: $border-light;
    color: $font-lighter;
    border-radius: 0;
    cursor: default;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $bg-indicators;
        border-color: $border-light;
        color: $font-lighter;
        box-shadow: none;
    } 
}
.btn-modify {
    background-color: $body-bg;
    color: $font-base;
    border-color: $font-light;
    border-radius: 0;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $body-bg;
        border-color: $color-skin-base;
        color: $color-skin-base;
    }
}