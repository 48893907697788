/*
 * 头部导航
 * 
 * @since 2018-5-17
 * @author xieyun
 */
 /*左侧*/
#header .top-nav {
  height: 34px;
  line-height: 34px;
  color: $header-nav-font;
  background-color: $bg-dark;
}
#header .nav-left {
  font-size: 0;
}
#header .nav-left-text,
#header .nav-left-marquee {
    display: inline-block;
    vertical-align: middle;
  }
#header .nav-left-marquee {
  // color: $color-skin-base;
  // margin-left: 140px;
  color: $header-nav-horn;
  margin-left: 40px;
  .marquee-icon,
  .marquee-text {
    display: inline-block;
    vertical-align: middle;
  }
  .marquee-icon {
    // color: $color-skin-base;
    color: $header-nav-horn;
  }
}

/*右侧*/
#header .nav-right {
  font-size: 0;
}
#header .nav-right-item,
#header .nav-right-line {
  display: inline-block;
  vertical-align: middle;
}
#header .nav-right-item {
  margin: 0 15px;
  &:last-child {
    margin-right: 0;
  }
  a {
    color: $header-nav-font;
    margin: 0 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  a.logout {
    color: $font-light;
  }
  a:hover {
    color: $link-hover-color;
  }
}
#header .nav-right-line {
  width: 1px;
  height: 12px;
  background-color: $divider-color;
}


/*
 * 头部菜单
 * 
 * @since 2018-5-17
 * @author xieyun
 */

/*菜单*/
#header .top-menu {
  height: 70px;
  background-color: $bg;
  box-shadow: 0px 1px 12px 0px rgba(55, 55, 156, 0.16);
}
#header .menu-list {
  font-size: 0;
  margin-left: 60px;
}
#header .menu-item,
#header .menu-logo {
  display: inline-block;
  vertical-align: middle; 
  position: relative;
  z-index: 1001;
}
#header .menu-item {
  width: 120px;
  text-align: center;
  margin: -30px 30px 0;
  .menu-item-title {
    display: inline-block;
    height: 70px;
    color: $header-menu-font;
    font-weight: bold;
    padding: 23px 4px;
    border-top: 2px solid $border-seat;
  }
  .menu-item-title:hover {
    color: $link-hover-color;
  }

  .dropdown-menu {
    min-width: 120px;
    padding: 0;
    border: 0 none;
    border-radius: 0;
    text-align: center;
    margin-top: 0;
    background: none;
    box-shadow: none;
    top: 60px;

    a {
      height: 34px;
      line-height: 34px;
      padding: 0;
      color: $font-lighter;
      margin-bottom: 2px;
      background-color: $color-skin-base;
    }
    a:hover {
      background-color: $color-skin-dark;
    }
  }
}
#header .menu-current {
  .menu-item-title {
    color: $color-skin-base;
    border-top: 2px solid $color-skin-base;
  }
}
#header .menu-logo {
  background: url('../img/logo-bg.png') no-repeat;
  width: 255px;
  height: 100px;
  padding-top: 18px;
}

/*操作*/
#header .menu-action {
  font-size: 0;
  padding-right: 13px;
  .action-search,
  .action-cart {
    display: inline-block;
    margin: 17px 0 0 30px;
  }

  .action-search {
    position: relative;
    .search-btn { 
      font-size: 21px;
      color: $color-skin-base;
      cursor: pointer;
      position: relative;
      top: 2px;
    }

    .search-wrapper {
      width: 400px;
      height: 70px;
      position: absolute;
      top: -17px;
      right: -10px;
      background-color: $bg;
      z-index: 1002;

      .search-arrow {
        width: 42px;
        text-align: center;
        border: 1px solid $border-seat;
        i {
          color: $font-light;
          line-height: 1;
          cursor: pointer;
        }
      }
      .form-control {
        border: 1px solid $color-skin-light;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        border-right: 0 none;
        top: 17px;
      }
      .search-btn {
        width: 40px;
        text-align: center;
        top: 0;
        button {
          border: 1px solid $color-skin-light;
          border-top-right-radius: 1px;
          border-bottom-right-radius: 1px;
          border-left: 0 none;
          i {
            line-height: 1;
            font-size: 21px;
            position: relative;
            left: 3px;
            color: $color-skin-base;
          }
        }
      }

      .dropdown-menu {
        min-width: 358px;
        left: 42px;
        border: 0 none;
        margin-top: -17px;
        padding: 0;
        box-shadow: 0px 4px 12px 0px rgba(94, 94, 203, 0.26);
        border-radius: 0px 0px 1px 1px;

        a {
          height: 30px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
          &:hover {
            color: $color-skin-base;
            background-color: $color-skin-lighter;
          }
        }

        .dropdown-header,
        .dropdown-lately {
          position: relative;
          i {
            line-height: 1;
            position: absolute;
            top: 6px;
            right: 20px;
            color: $font-light;
            cursor: pointer;
          }
        }
        .dropdown-header {
          padding: 0;
          b {
            display: block;
            height: 30px;
            line-height: 30px;
            color: $font-light;
            padding-left: 20px;
            padding-right: 35px;
            font-weight: normal;
          }
        }
        .dropdown-lately {
          i {
            line-height: 1;
            font-size: 0;
            top: 10px;
            right: 22px;
          }
          
          a {
            padding-right: 35px;
          } 

          &:hover {
            i:before {
              content: "\e60f";
              font-size: 12px;
            }
          }
        }

        .dropdown-emphasize {
          a {
            color: $text-price;
          }
        }

        .dropdown-item {
          &:hover {
            a {
              color: $color-skin-base;
            }
            background-color: $color-skin-lighter;
          }
        }
      }
    }
  }

  .action-cart {
    .action-cart-wrapper {
      display: block;
      width: 22px;
      height: 22px;
      color: $font-lighter;
      position: relative;

      .icon {
        font-size: 21px;
        color: $color-skin-base; 
        position: relative;
        top: 2px;
      }
      .num {
        display: none;
        height: 18px;
        padding: 0 5px;
        font-weight: normal;
        background-color: $bg-cart;
        border-radius: 18px;
        position: absolute;
        right: -13px;
        top: 0;
      }
    }
  }
}

