.slick-initialized {
	position: relative;
}
.slick-slide { 
	float: left; 
	img {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}
.slick-list {
	position: relative;
	overflow: hidden;
	outline: none;
}
.slick-track:after {
	content: '\20';
	display: block;
	height: 0;
	clear: both;
}

/* 左右箭头 */
.slick-prev,
.slick-next {
	text-align: center;
	background: none;
	border: 0 none;
	outline: none;
	position: absolute;
	top: 0;

	i {
		cursor: pointer;
		line-height: 1;
	}
}
.slick-prev {
	left: 0;
}
.slick-next {
	right: 0;
}
.slick-disabled {
	i {
		cursor: default;
	}
}

/* 圆点 */
.slick-dots {
	display: block;
	width: 100%;
	text-align: center;
	height: 4px;
	padding: 0;
	list-style: none;
	position: absolute;
	bottom: 35px;
	li {
	    position: relative;
	    z-index: 1000;
	    display: inline-block;
	    width: 10px;
	    height: 4px;
	    margin: 0 5px;
	    cursor: pointer;
	    button {
	    	display: block;
	    	width: 10px;
			height: 4px;
			font-size: 0;
			background-color: $bg-indicators-two;
			border: 0 none;
			outline: 0 none;
	    }
	}
	li.slick-active {
		button {
			background-color: $color-skin-base;
		}
	}
} 


