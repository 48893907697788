.gx-good-item {
	.item-wrapper {
		display: block;
		background-color: $bg;
		border: 1px solid $border-seat;
		position: relative;
		&:hover {
			opacity: 0.6;
			color: $font;
			border: 1px solid rgba($divider-color, 0.6);
			-webkit-box-shadow: 0px 2px 4px 0px rgba($color-skin-dark, 0.16); 
			-moz-box-shadow: 0px 2px 4px 0px rgba($color-skin-dark, 0.16); 
			box-shadow: 0px 2px 4px 0px rgba($color-skin-dark, 0.16); 
		}
	}

	.item-serial {
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 46px;
	    height: 46px;
	    text-align: center;
	    color: $font-serial;
	    background-color: $color-skin-base;
	    
	    b {
	      display: block;
	    }
	    b:first-child {
	      margin-top: 5px;
	    }
	    b:last-child {
	      font-weight: normal;
	      margin-top: -3px;
	    }
	}
	
	.item-avatar {
		padding: 30px;
		background-color: $bg;
		border-bottom: 1px solid $border-lighter;
		img {
		  display: block;
		  width: 100%;
		  height: 100%;
		  margin: 0 auto;
		}
	}

  .item-info {
    text-align: center;
    .title {
    	display: block;
    }
    .price {
      display: block;
      color: $text-price;
    }
  }
}

.gx-good-item:nth-child(4),
.gx-good-item:nth-child(5),
.gx-good-item:nth-child(6) {
  .item-serial {
    background-color: $bg-dark;
  }
}