@charset "UTF-8";
/*
 * 全局样式
 *
 * @since 2018-5-16
 * @author xieyun
 */
body {
  font-family: "Microsoft Yahei";
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333; }

html {
  height: 100%; }

/*
 * 包裹，一般样式都会包在该标签下，以使用一致的宽度
 *
 * @since 2018-5-16
 * @author xieyun
 */
.container {
  position: relative;
  width: 1160px;
  margin: 0 auto;
  padding: 0; }

#bodyer {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: 650px; }

/*
 * 链接
 *
 * @since 2018-5-16
 * @author xieyun
 */
a {
  color: #333; }
  a:hover, a:focus {
    color: #5e5ecb;
    text-decoration: none; }

/*
 * 按钮
 *
 * @since 2018-5-17
 * @author xieyun
 */
.btn:focus, .btn:active:focus, .btn.foucs, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none; }

/*
 * 截取
 *
 * @since 2018-5-17
 * @author xieyun
 */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*
 * 轮播图样式重置
 * 
 * @since 2018-5-19
 * @author xieyun
 */
.carousel-control.left,
.carousel-control.right {
  background-image: none;
  text-shadow: none;
  opacity: 1; }

/*
 * 标题
 *
 * @since 2018-5-20
 * @author xieyun
 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

/*
 * 字体大小+margin间距
 *
 * @since 2018-5-17
 * @author xieyun
 */
.mr5 {
  margin-right: 5px; }

.ml5 {
  margin-left: 5px; }

.mt5 {
  margin-top: 5px; }

.mb5 {
  margin-bottom: 5px; }

.f12 {
  font-size: 12px; }

.mr10 {
  margin-right: 10px; }

.ml10 {
  margin-left: 10px; }

.mt10 {
  margin-top: 10px; }

.mb10 {
  margin-bottom: 10px; }

.f14 {
  font-size: 14px; }

.mr15 {
  margin-right: 15px; }

.ml15 {
  margin-left: 15px; }

.mt15 {
  margin-top: 15px; }

.mb15 {
  margin-bottom: 15px; }

.f16 {
  font-size: 16px; }

.mr20 {
  margin-right: 20px; }

.ml20 {
  margin-left: 20px; }

.mt20 {
  margin-top: 20px; }

.mb20 {
  margin-bottom: 20px; }

.f18 {
  font-size: 18px; }

.mr25 {
  margin-right: 25px; }

.ml25 {
  margin-left: 25px; }

.mt25 {
  margin-top: 25px; }

.mb25 {
  margin-bottom: 25px; }

.f20 {
  font-size: 20px; }

.mr30 {
  margin-right: 30px; }

.ml30 {
  margin-left: 30px; }

.mt30 {
  margin-top: 30px; }

.mb30 {
  margin-bottom: 30px; }

.f22 {
  font-size: 22px; }

.mr35 {
  margin-right: 35px; }

.ml35 {
  margin-left: 35px; }

.mt35 {
  margin-top: 35px; }

.mb35 {
  margin-bottom: 35px; }

.f24 {
  font-size: 24px; }

.mr40 {
  margin-right: 40px; }

.ml40 {
  margin-left: 40px; }

.mt40 {
  margin-top: 40px; }

.mb40 {
  margin-bottom: 40px; }

.f26 {
  font-size: 26px; }

.mr45 {
  margin-right: 45px; }

.ml45 {
  margin-left: 45px; }

.mt45 {
  margin-top: 45px; }

.mb45 {
  margin-bottom: 45px; }

.f28 {
  font-size: 28px; }

.mr50 {
  margin-right: 50px; }

.ml50 {
  margin-left: 50px; }

.mt50 {
  margin-top: 50px; }

.mb50 {
  margin-bottom: 50px; }

.f30 {
  font-size: 30px; }

/*
 * 栅格系统
 *
 * @since 2018-5-19
 * @author xieyun
 */
.col-xs-1 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-1 {
  padding-left: 0;
  padding-right: 0; }

.col-md-1 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-1 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-2 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-2 {
  padding-left: 0;
  padding-right: 0; }

.col-md-2 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-2 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-3 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-3 {
  padding-left: 0;
  padding-right: 0; }

.col-md-3 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-3 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-4 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-4 {
  padding-left: 0;
  padding-right: 0; }

.col-md-4 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-4 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-5 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-5 {
  padding-left: 0;
  padding-right: 0; }

.col-md-5 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-5 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-6 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-6 {
  padding-left: 0;
  padding-right: 0; }

.col-md-6 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-6 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-7 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-7 {
  padding-left: 0;
  padding-right: 0; }

.col-md-7 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-7 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-8 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-8 {
  padding-left: 0;
  padding-right: 0; }

.col-md-8 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-8 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-9 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-9 {
  padding-left: 0;
  padding-right: 0; }

.col-md-9 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-9 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-10 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-10 {
  padding-left: 0;
  padding-right: 0; }

.col-md-10 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-10 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-11 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-11 {
  padding-left: 0;
  padding-right: 0; }

.col-md-11 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-11 {
  padding-left: 0;
  padding-right: 0; }

.col-xs-12 {
  padding-left: 0;
  padding-right: 0; }

.col-sm-12 {
  padding-left: 0;
  padding-right: 0; }

.col-md-12 {
  padding-left: 0;
  padding-right: 0; }

.col-lg-12 {
  padding-left: 0;
  padding-right: 0; }

/*
 * 头部导航
 * 
 * @since 2018-5-17
 * @author xieyun
 */
/*左侧*/
#header .top-nav {
  height: 34px;
  line-height: 34px;
  color: #ddd;
  background-color: #38383c; }

#header .nav-left {
  font-size: 0; }

#header .nav-left-text,
#header .nav-left-marquee {
  display: inline-block;
  vertical-align: middle; }

#header .nav-left-marquee {
  color: #FFFF00;
  margin-left: 40px; }
  #header .nav-left-marquee .marquee-icon,
  #header .nav-left-marquee .marquee-text {
    display: inline-block;
    vertical-align: middle; }
  #header .nav-left-marquee .marquee-icon {
    color: #FFFF00; }

/*右侧*/
#header .nav-right {
  font-size: 0; }

#header .nav-right-item,
#header .nav-right-line {
  display: inline-block;
  vertical-align: middle; }

#header .nav-right-item {
  margin: 0 15px; }
  #header .nav-right-item:last-child {
    margin-right: 0; }
  #header .nav-right-item a {
    color: #ddd;
    margin: 0 10px; }
    #header .nav-right-item a:last-child {
      margin-right: 0; }
  #header .nav-right-item a.logout {
    color: #999; }
  #header .nav-right-item a:hover {
    color: #5e5ecb; }

#header .nav-right-line {
  width: 1px;
  height: 12px;
  background-color: #ddd; }

/*
 * 头部菜单
 * 
 * @since 2018-5-17
 * @author xieyun
 */
/*菜单*/
#header .top-menu {
  height: 70px;
  background-color: #fff;
  box-shadow: 0px 1px 12px 0px rgba(55, 55, 156, 0.16); }

#header .menu-list {
  font-size: 0;
  margin-left: 60px; }

#header .menu-item,
#header .menu-logo {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1001; }

#header .menu-item {
  width: 120px;
  text-align: center;
  margin: -30px 30px 0; }
  #header .menu-item .menu-item-title {
    display: inline-block;
    height: 70px;
    color: #38383c;
    font-weight: bold;
    padding: 23px 4px;
    border-top: 2px solid #fff; }
  #header .menu-item .menu-item-title:hover {
    color: #5e5ecb; }
  #header .menu-item .dropdown-menu {
    min-width: 120px;
    padding: 0;
    border: 0 none;
    border-radius: 0;
    text-align: center;
    margin-top: 0;
    background: none;
    box-shadow: none;
    top: 60px; }
    #header .menu-item .dropdown-menu a {
      height: 34px;
      line-height: 34px;
      padding: 0;
      color: #fff;
      margin-bottom: 2px;
      background-color: #5e5ecb; }
    #header .menu-item .dropdown-menu a:hover {
      background-color: #42428e; }

#header .menu-current .menu-item-title {
  color: #5e5ecb;
  border-top: 2px solid #5e5ecb; }

#header .menu-logo {
  background: url("../img/logo-bg.png") no-repeat;
  width: 255px;
  height: 100px;
  padding-top: 18px; }

/*操作*/
#header .menu-action {
  font-size: 0;
  padding-right: 13px; }
  #header .menu-action .action-search,
  #header .menu-action .action-cart {
    display: inline-block;
    margin: 17px 0 0 30px; }
  #header .menu-action .action-search {
    position: relative; }
    #header .menu-action .action-search .search-btn {
      font-size: 21px;
      color: #5e5ecb;
      cursor: pointer;
      position: relative;
      top: 2px; }
    #header .menu-action .action-search .search-wrapper {
      width: 400px;
      height: 70px;
      position: absolute;
      top: -17px;
      right: -10px;
      background-color: #fff;
      z-index: 1002; }
      #header .menu-action .action-search .search-wrapper .search-arrow {
        width: 42px;
        text-align: center;
        border: 1px solid #fff; }
        #header .menu-action .action-search .search-wrapper .search-arrow i {
          color: #999;
          line-height: 1;
          cursor: pointer; }
      #header .menu-action .action-search .search-wrapper .form-control {
        border: 1px solid #9a9adc;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        border-right: 0 none;
        top: 17px; }
      #header .menu-action .action-search .search-wrapper .search-btn {
        width: 40px;
        text-align: center;
        top: 0; }
        #header .menu-action .action-search .search-wrapper .search-btn button {
          border: 1px solid #9a9adc;
          border-top-right-radius: 1px;
          border-bottom-right-radius: 1px;
          border-left: 0 none; }
          #header .menu-action .action-search .search-wrapper .search-btn button i {
            line-height: 1;
            font-size: 21px;
            position: relative;
            left: 3px;
            color: #5e5ecb; }
      #header .menu-action .action-search .search-wrapper .dropdown-menu {
        min-width: 358px;
        left: 42px;
        border: 0 none;
        margin-top: -17px;
        padding: 0;
        box-shadow: 0px 4px 12px 0px rgba(94, 94, 203, 0.26);
        border-radius: 0px 0px 1px 1px; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu a {
          height: 30px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0; }
          #header .menu-action .action-search .search-wrapper .dropdown-menu a:hover {
            color: #5e5ecb;
            background-color: #e5e5f7; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-header,
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-lately {
          position: relative; }
          #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-header i,
          #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-lately i {
            line-height: 1;
            position: absolute;
            top: 6px;
            right: 20px;
            color: #999;
            cursor: pointer; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-header {
          padding: 0; }
          #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-header b {
            display: block;
            height: 30px;
            line-height: 30px;
            color: #999;
            padding-left: 20px;
            padding-right: 35px;
            font-weight: normal; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-lately i {
          line-height: 1;
          font-size: 0;
          top: 10px;
          right: 22px; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-lately a {
          padding-right: 35px; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-lately:hover i:before {
          content: "\e60f";
          font-size: 12px; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-emphasize a {
          color: #db0000; }
        #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-item:hover {
          background-color: #e5e5f7; }
          #header .menu-action .action-search .search-wrapper .dropdown-menu .dropdown-item:hover a {
            color: #5e5ecb; }
  #header .menu-action .action-cart .action-cart-wrapper {
    display: block;
    width: 22px;
    height: 22px;
    color: #fff;
    position: relative; }
    #header .menu-action .action-cart .action-cart-wrapper .icon {
      font-size: 21px;
      color: #5e5ecb;
      position: relative;
      top: 2px; }
    #header .menu-action .action-cart .action-cart-wrapper .num {
      display: none;
      height: 18px;
      padding: 0 5px;
      font-weight: normal;
      background-color: #db0000;
      border-radius: 18px;
      position: absolute;
      right: -13px;
      top: 0; }

/*
 * 底部
 * 
 * @since 2018-5-18
 * @author xieyun
 */
#footer {
  background: url("../img/footer-bg.png") no-repeat center bottom;
  height: 287px; }

#footer .container {
  width: 1200px;
  padding-top: 36px; }

/*销售保证*/
#footer .footer-guarantee {
  padding: 40px 155px;
  border-bottom: 1px solid #333; }

#footer .guarantee-item {
  text-align: center; }
  #footer .guarantee-item .icon,
  #footer .guarantee-item .text {
    display: inline-block;
    vertical-align: middle; }
  #footer .guarantee-item .icon {
    font-size: 46px;
    color: #fff; }
  #footer .guarantee-item .text {
    color: #fff; }

/*友情链接*/
#footer .main-link {
  font-size: 0;
  text-align: center;
  padding: 35px 0 15px; }
  #footer .main-link .link-item,
  #footer .main-link .link-line {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #999; }
  #footer .main-link .link-item:hover {
    color: #5e5ecb; }
  #footer .main-link .link-line {
    width: 1px;
    height: 13px;
    margin: 0 10px;
    background-color: #999; }

#footer .main-info {
  color: #999;
  text-align: center; }
  #footer .main-info .info-item {
    margin: 0 2px; }

.btn-default {
  height: 36px;
  background-color: #fff;
  border-color: #ddd;
  border-radius: 1px;
  color: #333; }
  .btn-default:hover, .btn-default:active, .btn-default:focus, .btn-default:active:focus {
    background-color: #fff;
    border-color: #ddd;
    color: #333;
    box-shadow: none; }

.btn-primary {
  height: 36px;
  background-color: #5e5ecb;
  border-color: #5e5ecb;
  border-radius: 1px;
  color: #fff; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:focus {
    background-color: #42428e;
    border-color: #42428e;
    color: #fff; }

.btn-buy {
  background-color: #fff;
  color: #5e5ecb;
  border-radius: 0; }
  .btn-buy:hover, .btn-buy:active, .btn-buy:focus, .btn-buy:active:focus {
    background-color: #e5e5f7;
    border-color: #5e5ecb;
    color: #5e5ecb; }

.btn-order {
  background-color: #db0000;
  border-color: #db0000;
  color: #fff;
  border-radius: 0; }
  .btn-order:hover, .btn-order:active, .btn-order:focus, .btn-order:active:focus {
    background-color: #db0000;
    border-color: #db0000;
    color: #fff; }

.btn-no {
  background-color: #ccc;
  border-color: #ccc;
  color: #fff;
  border-radius: 0;
  cursor: default; }
  .btn-no:hover, .btn-no:active, .btn-no:focus, .btn-no:active:focus {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff;
    box-shadow: none; }

.btn-modify {
  background-color: #f5f5f5;
  color: #666;
  border-color: #999;
  border-radius: 0; }
  .btn-modify:hover, .btn-modify:active, .btn-modify:focus, .btn-modify:active:focus {
    background-color: #f5f5f5;
    border-color: #5e5ecb;
    color: #5e5ecb; }

.gx-check input[type="checkbox"],
.gx-check input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer; }

.gx-check label {
  cursor: pointer;
  font-size: 0;
  position: relative; }
  .gx-check label i,
  .gx-check label b {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    font-size: 12px; }
    .gx-check label i span,
    .gx-check label b span {
      color: #999; }
  .gx-check label i {
    line-height: 1;
    font-style: normal;
    background-color: #fff; }
  .gx-check label b {
    padding-left: 10px; }

.gx-check input[type="checkbox"] + i:before {
  font-family: "iconfont" !important;
  content: '\e621'; }

.gx-check input[type="checkbox"]:checked + i:before {
  font-family: "iconfont" !important;
  content: '\e623';
  color: #5e5ecb; }

.gx-check input[type="radio"] + i:before {
  font-family: "iconfont" !important;
  content: '\e676'; }

.gx-check input[type="radio"]:checked + i:before {
  font-family: "iconfont" !important;
  content: '\e679';
  color: #5e5ecb; }

/*
 * modal基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.modal {
  text-align: center; }
  .modal::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.modal .modal-dialog {
  width: auto;
  border-top: 2px solid #5e5ecb; }

.modal .modal-content {
  border: 1px solid #ddd;
  border-top: 0 none;
  border-radius: 1px;
  box-shadow: none; }

.modal .modal-header {
  padding: 0;
  border-bottom: 0 none; }
  .modal .modal-header .modal-title {
    font-size: 16px;
    color: #333; }
  .modal .modal-header .close {
    font-size: 0;
    font-weight: normal;
    color: #999;
    opacity: 1;
    margin-top: 5px;
    outline: none; }
  .modal .modal-header .close:before {
    font-family: "iconfont" !important;
    font-size: 14px;
    content: "\e60f"; }

.modal .modal-body {
  padding: 0 50px; }

.modal .modal-footer {
  padding: 0 50px 50px;
  border-top: 0 none; }

@media (min-width: 768px) {
  .modal-dialog {
    display: inline-block;
    vertical-align: middle;
    text-align: left; } }

/*
 * dialog基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.gx-modal-dialog .modal-dialog {
  min-width: 400px; }

.gx-modal-dialog .modal-header {
  padding: 25px 50px; }

.gx-modal-dialog .modal-footer {
  text-align: center; }
  .gx-modal-dialog .modal-footer .btn-ok {
    width: 100%;
    height: 42px;
    color: #fff;
    font-size: 16px;
    border-radius: 1px;
    background-color: #5e5ecb; }
    .gx-modal-dialog .modal-footer .btn-ok:hover {
      background-color: #42428e; }
  .gx-modal-dialog .modal-footer .btn-no {
    color: #fff;
    border-radius: 1px;
    background-color: #ccc;
    cursor: default; }
    .gx-modal-dialog .modal-footer .btn-no:hover {
      background-color: #ccc; }

/*
 * say基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.gx-modal-say .modal-dialog {
  min-width: 140px; }

.gx-modal-say .modal-header {
  padding: 20px 0 5px; }
  .gx-modal-say .modal-header i {
    display: block;
    line-height: 1;
    font-style: normal;
    text-align: center;
    font-size: 34px;
    color: #00db67; }
  .gx-modal-say .modal-header i.success:before {
    font-family: "iconfont" !important;
    content: "\e61f"; }
  .gx-modal-say .modal-header i.error:before {
    font-family: "iconfont" !important;
    content: "\e627";
    color: #db0000; }

.gx-modal-say .modal-body {
  text-align: center;
  padding-bottom: 15px; }

/*
 * confirm基础样式
 * 
 * @since 2018-6-6
 * @author xieyun
 */
.gx-modal-confirm .modal-dialog {
  min-width: 400px; }

.gx-modal-confirm .modal-header {
  padding: 25px 50px; }

.gx-modal-confirm .modal-body {
  padding: 20px 50px 25px;
  text-align: center; }
  .gx-modal-confirm .modal-body .icon {
    display: block;
    font-size: 35px;
    color: #db0000;
    line-height: 1; }
  .gx-modal-confirm .modal-body .text {
    margin-top: 5px;
    display: block;
    font-size: 16px; }

.gx-modal-confirm .modal-footer {
  text-align: center; }
  .gx-modal-confirm .modal-footer .btn {
    margin: 0 25px; }
  .gx-modal-confirm .modal-footer .btn-ok {
    width: 80px;
    height: 42px;
    color: #fff;
    font-size: 16px;
    border-radius: 1px;
    background-color: #5e5ecb; }
    .gx-modal-confirm .modal-footer .btn-ok:hover {
      background-color: #42428e; }
  .gx-modal-confirm .modal-footer .btn-cancel {
    width: 80px;
    height: 42px;
    color: #333;
    border-radius: 1px;
    border-color: #ddd;
    background-color: #fff; }
    .gx-modal-confirm .modal-footer .btn-cancel:hover {
      border-color: #5e5ecb;
      color: #5e5ecb; }

.form-control {
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 1px;
  box-shadow: none; }
  .form-control:focus {
    border-color: #9a9adc;
    box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    font-size: 14px; }
  .form-control:-moz-placeholder {
    font-size: 14px; }
  .form-control::-moz-placeholder {
    font-size: 14px; }
  .form-control:-ms-input-placeholder {
    font-size: 14px; }

.form-group {
  margin-bottom: 30px; }
  .form-group .input-group-title {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: normal; }
  .form-group .form-control {
    height: 42px; }
  .form-group .input-group-error {
    display: table-cell;
    vertical-align: middle; }

.form-verify {
  position: relative; }
  .form-verify .form-control {
    padding-right: 90px; }
  .form-verify .input-group-btn {
    width: 90px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0; }
    .form-verify .input-group-btn .line {
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 18px;
      background-color: #ddd; }
    .form-verify .input-group-btn .acquireCode {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 42px;
      line-height: 42px;
      color: #666;
      font-weight: normal;
      border: 0 none;
      background: none;
      cursor: pointer; }
      .form-verify .input-group-btn .acquireCode:hover {
        color: #5e5ecb; }
    .form-verify .input-group-btn .noClick {
      cursor: default; }
      .form-verify .input-group-btn .noClick:hover {
        color: #666; }
    .form-verify .input-group-btn img {
      cursor: pointer;
      border: 1px solid #ddd;
      border-left: 0 none; }

.form-textarea .input-group-title {
  vertical-align: top; }

.form-textarea .form-control {
  height: auto;
  resize: none; }

.form-province .input-group-province {
  display: table-cell;
  font-size: 0; }

.gx-alert {
  border: 0 none;
  border-radius: 1px;
  padding: 0 10px;
  margin: 0;
  color: #db0000;
  background-color: #fff; }
  .gx-alert .media,
  .gx-alert .media-body {
    overflow: visible; }
  .gx-alert .media-left {
    padding-right: 5px; }

.bootstrap-select.gx-select.open .dropdown-toggle {
  border: 1px solid #ddd; }

.bootstrap-select.gx-select .dropdown-toggle {
  padding: 0 10px;
  height: 30px;
  color: #333;
  background-color: #fff;
  font-size: 12px;
  box-shadow: none;
  padding-right: 40px;
  margin-left: 0; }
  .bootstrap-select.gx-select .dropdown-toggle:active, .bootstrap-select.gx-select .dropdown-toggle:hover, .bootstrap-select.gx-select .dropdown-toggle:focus {
    background-color: #fff;
    color: #333; }
  .bootstrap-select.gx-select .dropdown-toggle:focus {
    outline: none !important; }
  .bootstrap-select.gx-select .dropdown-toggle .caret {
    width: 30px;
    text-align: center;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    color: #999;
    border: 0 none;
    border-left: 1px solid #ddd;
    background-color: #f5f5f5;
    top: 2px;
    right: 0; }
    .bootstrap-select.gx-select .dropdown-toggle .caret:after {
      font-family: "iconfont" !important;
      content: "\e622"; }

.bootstrap-select.gx-select .dropdown-menu {
  font-size: 12px;
  box-shadow: none;
  border-radius: 1px;
  border: 1px solid #ddd;
  margin: -1px 0;
  padding: 0; }
  .bootstrap-select.gx-select .dropdown-menu a {
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    background-color: #fff;
    outline: none; }
    .bootstrap-select.gx-select .dropdown-menu a:hover {
      color: #5e5ecb;
      background-color: #e5e5f7; }

.page-item-to {
  margin: 0 15px; }

.page-to-control {
  width: 60px;
  height: 46px;
  margin: 0 15px;
  border: 1px solid #ddd; }

.pagination {
  line-height: 1.46; }
  .pagination > li > a, .pagination > li span {
    font-size: 14px;
    color: #666;
    padding: 10px 16px;
    line-height: 1.46;
    background: #fff;
    margin: 2.5px;
    border-color: #ddd;
    border-radius: 0; }
    .pagination > li > a:hover, .pagination > li span:hover {
      color: #5e5ecb;
      background-color: #fff;
      border-color: #5e5ecb; }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 10px 15px; }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px 15px; }
  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    background-color: #5e5ecb;
    color: #fff; }
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus,
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus {
    color: #ddd; }

/*
 * 右侧悬浮
 * 
 * @since 2018-5-21
 * @author xieyun
 */
#suspension {
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 1001; }

#suspension .suspension-wrapper {
  height: 192px; }

#suspension .suspension-qq,
#suspension .suspension-service,
#suspension .suspension-top {
  display: block;
  width: 60px;
  text-align: center;
  height: 60px;
  border-radius: 1px;
  color: #fff;
  cursor: pointer;
  margin-top: 4px; }
  #suspension .suspension-qq .icon,
  #suspension .suspension-service .icon,
  #suspension .suspension-top .icon {
    display: block;
    line-height: 1;
    color: #fff;
    position: relative;
    top: 10px; }
  #suspension .suspension-qq .text,
  #suspension .suspension-service .text,
  #suspension .suspension-top .text {
    display: block;
    margin-top: 15px; }

#suspension .suspension-qq,
#suspension .suspension-service {
  position: relative;
  background-color: #38383c; }

#suspension .qq-overlay,
#suspension .service-overlay {
  width: 120px;
  background-color: #38383c;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  right: 62px; }

#suspension .qq-overlay {
  height: 160px; }
  #suspension .qq-overlay img {
    padding: 7px; }
  #suspension .qq-overlay b {
    display: block;
    font-weight: normal;
    text-align: center;
    color: #ddd; }

#suspension .service-overlay {
  height: 60px; }
  #suspension .service-overlay b {
    display: block;
    font-weight: normal;
    text-align: center;
    color: #ddd;
    margin: 10px 0 0; }
    #suspension .service-overlay b:last-child {
      margin-top: 5px; }

#suspension .suspension-top {
  display: none;
  background-color: #ddd; }

#suspension .suspension-wkit {
  position: relative; }
  #suspension .suspension-wkit .top {
    display: block;
    height: 30px;
    line-height: 30px;
    margin-right: 7px;
    background-color: #fff;
    position: relative;
    top: 31px;
    z-index: 10; }
  #suspension .suspension-wkit .icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 8px; }

.gx-good-item .item-wrapper {
  display: block;
  background-color: #fff;
  border: 1px solid #fff;
  position: relative; }
  .gx-good-item .item-wrapper:hover {
    opacity: 0.6;
    color: #333;
    border: 1px solid rgba(221, 221, 221, 0.6);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(66, 66, 142, 0.16);
    -moz-box-shadow: 0px 2px 4px 0px rgba(66, 66, 142, 0.16);
    box-shadow: 0px 2px 4px 0px rgba(66, 66, 142, 0.16); }

.gx-good-item .item-serial {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
  text-align: center;
  color: #f5f5f5;
  background-color: #5e5ecb; }
  .gx-good-item .item-serial b {
    display: block; }
  .gx-good-item .item-serial b:first-child {
    margin-top: 5px; }
  .gx-good-item .item-serial b:last-child {
    font-weight: normal;
    margin-top: -3px; }

.gx-good-item .item-avatar {
  padding: 30px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5; }
  .gx-good-item .item-avatar img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto; }

.gx-good-item .item-info {
  text-align: center; }
  .gx-good-item .item-info .title {
    display: block; }
  .gx-good-item .item-info .price {
    display: block;
    color: #db0000; }

.gx-good-item:nth-child(4) .item-serial,
.gx-good-item:nth-child(5) .item-serial,
.gx-good-item:nth-child(6) .item-serial {
  background-color: #38383c; }

.slick-initialized {
  position: relative; }

.slick-slide {
  float: left; }
  .slick-slide img {
    width: 100%;
    height: 100%;
    cursor: pointer; }

.slick-list {
  position: relative;
  overflow: hidden;
  outline: none; }

.slick-track:after {
  content: '\20';
  display: block;
  height: 0;
  clear: both; }

/* 左右箭头 */
.slick-prev,
.slick-next {
  text-align: center;
  background: none;
  border: 0 none;
  outline: none;
  position: absolute;
  top: 0; }
  .slick-prev i,
  .slick-next i {
    cursor: pointer;
    line-height: 1; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-disabled i {
  cursor: default; }

/* 圆点 */
.slick-dots {
  display: block;
  width: 100%;
  text-align: center;
  height: 4px;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 35px; }
  .slick-dots li {
    position: relative;
    z-index: 1000;
    display: inline-block;
    width: 10px;
    height: 4px;
    margin: 0 5px;
    cursor: pointer; }
    .slick-dots li button {
      display: block;
      width: 10px;
      height: 4px;
      font-size: 0;
      background-color: #808080;
      border: 0 none;
      outline: 0 none; }
  .slick-dots li.slick-active button {
    background-color: #5e5ecb; }
