.gx-check { 
    input[type="checkbox"],
    input[type="radio"]{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    label {
        cursor: pointer;
        font-size: 0;
        position: relative;
        i,
        b {
            display: inline-block;
            vertical-align: middle;
            font-weight: normal; 
            font-size: 12px;
            span{
                color:$font-light;
            }
        }

        i {
            line-height: 1;
            font-style: normal;
            background-color: $bg;
        }
        b {
            padding-left: 10px;
        }
    }
    input[type="checkbox"] {
        &+i:before {
            font-family: "iconfont" !important;
            content: '\e621';
        }
        &:checked {
            &+i:before {
                font-family: "iconfont" !important;
                content: '\e623';
                color: $color-skin-base;
            }
        }
    }
    input[type="radio"] {
        &+i:before {
            font-family: "iconfont" !important;
            content: '\e676';
        }
        &:checked {
            &+i:before {
                font-family: "iconfont" !important;
                content: '\e679';
                color: $color-skin-base;
            }
        }
    }
}