/*
 * modal基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.modal {
    text-align: center;
    &::before{
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        // margin-right: -4px;
    }
}
.modal .modal-dialog {
    width: auto;
    border-top: 2px solid $color-skin-base;
}
.modal .modal-content {
    border: 1px solid $bg-light;
    border-top: 0 none;
    border-radius: 1px;
    box-shadow: none;
}

.modal .modal-header {
    padding: 0;
    border-bottom: 0 none;
    .modal-title {
        font-size: 16px;
        color: $font;
    }
    .close {
        font-size: 0;
        font-weight: normal;
        color: $font-light;
        opacity: 1;
        margin-top: 5px;
        outline: none;
    }
    .close:before {
        font-family: "iconfont" !important;
        font-size: 14px;
        content: "\e60f";
    }
}

.modal .modal-body {
    padding: 0 50px;
}

.modal .modal-footer {
    padding: 0 50px 50px;
    border-top: 0 none;
}

@media (min-width: 768px){
    .modal-dialog {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }
}


/*
 * dialog基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.gx-modal-dialog .modal-dialog  {
    min-width: 400px;
}
.gx-modal-dialog .modal-header {
    padding: 25px 50px;
}

.gx-modal-dialog .modal-footer {
    text-align: center;
    .btn-ok {
        width: 100%;
        height: 42px;
        color: $font-lighter;
        font-size: 16px;
        border-radius: 1px;
        background-color: $color-skin-base;
        &:hover {
           background-color: $color-skin-dark; 
        }
    }
    .btn-no {
        color: $font-lighter;
        border-radius: 1px;
        background-color: $bg-indicators;
        cursor: default;    
        &:hover {
           background-color: $bg-indicators; 
        }
    }
}


/*
 * say基础样式
 * 
 * @since 2018-5-23
 * @author xieyun
 */
.gx-modal-say .modal-dialog {
    min-width: 140px;
}
.gx-modal-say .modal-header {
    padding: 20px 0 5px; 
    i {
        display: block;
        line-height: 1;
        font-style: normal;
        text-align: center;
        font-size: 34px;
        color: $icon-success;
    }
    i.success:before {
        font-family: "iconfont" !important;
        content: "\e61f";
    }
    i.error:before {
        font-family: "iconfont" !important;
        content: "\e627";
        color: $icon-fail;
    }
}
.gx-modal-say .modal-body {
    text-align: center;
    padding-bottom: 15px;
}


/*
 * confirm基础样式
 * 
 * @since 2018-6-6
 * @author xieyun
 */
.gx-modal-confirm .modal-dialog {
    min-width: 400px;
}
.gx-modal-confirm .modal-header {
    padding: 25px 50px;
}

.gx-modal-confirm .modal-body { 
    padding: 20px 50px 25px;
    text-align: center;
    .icon {
        display: block;
        font-size: 35px;
        color: $font-error;
        line-height: 1;
    }
    .text {
        margin-top: 5px;
        display: block;
        font-size: 16px;
    }
}

.gx-modal-confirm .modal-footer {
    text-align: center;
    .btn {
        margin: 0 25px;
    }
    .btn-ok {
        width: 80px;
        height: 42px;
        color: $font-lighter;
        font-size: 16px;
        border-radius: 1px;
        background-color: $color-skin-base;
        &:hover {
           background-color: $color-skin-dark; 
        }
    }
    .btn-cancel {
        width: 80px;
        height: 42px;
        color: $font;
        border-radius: 1px;
        border-color: $divider-color;
        background-color: $bg; 
        &:hover {
            border-color: $color-skin-base;
            color: $color-skin-base;
        }
    }
}