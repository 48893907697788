/*
 * 右侧悬浮
 * 
 * @since 2018-5-21
 * @author xieyun
 */
#suspension {
	position: fixed;
	bottom: 30px;
	right: 10px;
	z-index: 1001;
}
#suspension .suspension-wrapper {
	height: 192px;
}

#suspension .suspension-qq,
#suspension .suspension-service,
#suspension .suspension-top {
	display: block;
	width: 60px;
	text-align: center;
	height: 60px;
	border-radius: 1px;
	color: $font-lighter;
	cursor: pointer;
	margin-top: 4px;

	.icon {
		display: block;
		line-height: 1;
		color: $font-lighter;
		position: relative;
		top: 10px;
	}
	.text {
		display: block;
		margin-top: 15px;
	}
}
#suspension .suspension-qq,
#suspension .suspension-service {
	position: relative;
	background-color: $bg-dark;
}
#suspension .qq-overlay,
#suspension .service-overlay {
	width: 120px;
	background-color: $bg-dark;
	border-radius: 1px;
	position: absolute;
	bottom: 0;
	right: 62px;
}
#suspension .qq-overlay {
	height: 160px;
	img {
		padding: 7px;
	}
	b {
		display: block;
		font-weight: normal;
		text-align: center;
		color: $suspension-font;
	}
}
#suspension .service-overlay {
	height: 60px;
	b {
		display: block;
		font-weight: normal;
		text-align: center;
		color: $suspension-font;
		margin: 10px 0 0;
		&:last-child {
			margin-top: 5px;
		}
	}
}
#suspension .suspension-top {
	display: none;
	background-color: $bg-light;
}

#suspension .suspension-wkit {
	position: relative;
	.top {
		display: block;
		height: 30px;
		line-height: 30px;
		margin-right: 7px;
		background-color: $bg;
		position: relative;
		top: 31px;
		z-index: 10;
	}
	.icon {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 8px;
	}
}