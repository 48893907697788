.form-control {
	height: 36px;
    border: 1px solid $divider-color;
    border-radius: 1px;
    box-shadow: none;

    &:focus {
    	border-color: $color-skin-light;
    	box-shadow: none;
    }

    &::-webkit-input-placeholder {
        font-size: 14px;
    }
    &:-moz-placeholder {
        font-size: 14px;
    }
    &::-moz-placeholder {
        font-size: 14px;
    }
    &:-ms-input-placeholder {
        font-size: 14px;
    }
}

.form-group {
	margin-bottom: 30px;
	.input-group-title {
		display: table-cell;
		vertical-align: middle;
		white-space: nowrap;
		font-weight: normal;
	}
	.form-control {
		height: 42px;
	}
	.input-group-error {
		display: table-cell;
		vertical-align: middle;
	}
}

.form-verify {
	position: relative;
	.form-control {
		padding-right: 90px;
	}
	.input-group-btn {
		width: 90px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 0;
		.line {
			display: inline-block;
			vertical-align: middle;
			width: 1px;
			height: 18px;
			background-color: $bg-light;
		}
		.acquireCode {
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			height: 42px;
			line-height: 42px;
			color: $font-base;
			font-weight: normal;
			border: 0 none;
			background: none;
			cursor: pointer;
			&:hover {
				color: $color-skin-base;
			}
		}
		.noClick {
			cursor: default;
			&:hover {
				color: $font-base;
			}
		}
		img {
			cursor: pointer;
			border: 1px solid $divider-color;
			border-left: 0 none;
		}
	}
}

.form-textarea {
	.input-group-title {
		vertical-align: top;
	}
	.form-control {
		height: auto;
		resize: none;
	}
} 

.form-province {
	.input-group-province {
		display: table-cell;
		font-size: 0;
	}
}
