.page-item-to{
    margin: 0 15px;
}
.page-to-control{
    width: 60px;
    height: 46px;
    margin: 0 15px;
    border: 1px solid $divider-color
}
.pagination{
    line-height: 1.46;
    >li{
        >a,span{
            font-size: 14px;
            color: $font-base;
            padding: 10px 16px;
            line-height: 1.46;
            background: $bg;
            margin: 2.5px;
            border-color: $divider-color;
            border-radius: 0;
            &:hover{
                color: $color-skin-base;
                background-color: $bg;
                border-color:$color-skin-base;
            }
        }
        &:first-child{ 
            >a,
            >span{
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                padding: 10px 15px;
            }
        }
        &:last-child{ 
            >a,
            >span{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 10px 15px;
            }
        }
    }
    >.active{
        >a,
        >a:hover,
        >a:focus,
        >span,
        >span:hover,
        >span:focus{
            background-color: $color-skin-base;
            // border-color: $bg-lighter;
            color: $font-lighter;
        }
    }
    >.disabled{
        >a,
        >a:hover,
        >a:focus,
        >span,
        >span:hover,
        >span:focus{
            // background-color: $bg-dark;
            // border-color: $bg-dark;
            color: $bg-light;
        }
    }
}