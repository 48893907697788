.gx-alert {
    border: 0 none;
    border-radius: 1px;
    padding: 0 10px;
    margin: 0;
    color: $font-error;
    background-color: $bg;

	.media, 
	.media-body {
		overflow: visible;
	}
    .media-left {
    	padding-right: 5px;
    }
}
// .alert-warning {
//     background-color: $bg-warning;
//     color: $brand-warning;
// }
// .alert-danger {
//     background-color: $bg-danger;
//     color: $brand-danger;
// }
